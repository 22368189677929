.leftNavBar{
    background-color: rgb(226, 225, 225);
    padding-right: 0px !important;
}

.LeftNavColumnContainer{
    margin-left: 0px !important;
    margin-right: 0px !important;
    height: 100% !important;
}

.col{
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.scrollScene{
    padding-left: 0px !important;
    padding-right: 0px !important;
    width: 100% !important;
    height: 100% !important;
}

.row{
    width:100% !important;
}

.LeftBarButtonGroup{
    position: relative;
    z-index: 1;
    margin-top: 25px;
    padding-left: 45px;
}

.LeftBarButtonGroupCol{
    position: absolute;
    z-index: 1;
    margin-top: 25px;
    padding-left: 45px;
}