.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.cuteButton{
  float:right;
  margin-top: -15px;
}

/* .list-group {
  width: 500px;
} */
