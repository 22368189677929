.gptButton{
    margin-bottom: 10px;
}

.gptForm{
    margin-top: 20px;
}

.clearChatHistory{
    margin-left: 15px;
    margin-bottom: 10px;
}

.loadingIcon2{
    margin-bottom: 10px;
    margin-left:15px;
}