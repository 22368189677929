body {
  margin: 0;
  padding: 0;
  color: #333;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Open Sans", sans-serif !important;
  height: 100% !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "PT Serif", serif;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 1rem;
}
input[type="file"] {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
