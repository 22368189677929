form textarea {
  height: 300px;
  font-size: 1.5rem;
}

.Notes {
  height: 300px;
  font-size: 1.5rem;
  margin-top: 20px;
}



.ModalButton{
  margin-left: 15px;
  margin-top: 12px;
  margin-right: 10px;
}

.toggleGroup{
  margin-left: 20px;
}

