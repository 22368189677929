form textarea {
  height: 300px;
  font-size: 1.5rem;

}

.NewNote{
  height: 300px;
  font-size: 1.5rem;
  margin-top: 20px;
}
